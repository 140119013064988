/* بخش رتبه‌بندی دانشگاه‌ها و مقایسه */
.uni-ranking-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-ranking-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 2rem;
    font-weight: 700;
}

.uni-ranking-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    border-radius: 12px;
    overflow: hidden;
}

.uni-ranking-table th,
.uni-ranking-table td {
    padding: 1rem;
    text-align: center;
    border-bottom: 2px solid #e0e0e0;
    font-size: 1rem;
    color: #4a4a4a;
    font-family: 'Open Sans', sans-serif;
}

.uni-ranking-table th {
    background-color: #4b2e83;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.uni-ranking-table tr:hover {
    background-color: #f0f7ff;
    transform: translateY(-5px);
}

/* بخش ماشین حساب شهریه */
.uni-calculator-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-calculator-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 2rem;
    font-weight: 700;
}

.uni-calculator-form {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.uni-calculator-form label {
    font-size: 1rem;
    color: #333;
}

.uni-calculator-form select {
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.uni-calculator-form button {
    padding: 0.8rem;
    background-color: #4b2e83;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.uni-calculator-form button:hover {
    background-color: #3a216a;
}

.uni-calculator-result {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    color: #4b2e83;
}

/* کادر نمایش نتیجه تبدیل ارزها در ماشین حساب */
.conversion-card {
    background-color: #fff;
    border: 2px solid #4b2e83;
    border-radius: 12px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.conversion-card h3 {
    font-size: 1.5rem;
    color: #4b2e83;
    margin-bottom: 1rem;
}

.conversion-card p {
    font-size: 1.1rem;
    color: #333;
    margin: 0.5rem 0;
}

.conversion-card hr {
    margin: 1rem 0;
    border: none;
    border-top: 1px solid #ccc;
}

/* بخش نقشه دانشگاه */
.uni-map-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-map-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 2rem;
    font-weight: 700;
}

.uni-map-container iframe {
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

/* Media Queries مرتبط با بخش پایین */
@media screen and (max-width: 768px) {
    .uni-ranking-table {
        width: 100%;
    }
}

@media screen and (max-width: 480px) {
    .uni-ranking-title,
    .uni-calculator-title,
    .uni-map-title {
        font-size: 1.6rem;
    }
}

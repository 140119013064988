/* جدول شهریه */
.uni-tuition-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-tuition-title {
    font-size: 2rem;
    color: #4b2e83;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.uni-tuition-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    transform: translateY(10px);
    animation: tableAnimation 0.5s ease-in-out;
}

.uni-tuition-table th,
.uni-tuition-table td {
    padding: 1.5rem;
    text-align: center;
    vertical-align: middle;
    border-bottom: 2px solid #e0e0e0;
    font-size: 1rem;
    color: #4a4a4a;
    font-family: 'Open Sans', sans-serif;
}

.uni-tuition-table th {
    background-color: #4b2e83;
    color: #ffffff;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.uni-tuition-table tr:hover {
    background-color: #f0f7ff;
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.uni-tuition-table td {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

.uni-tuition-table td:first-child {
    font-weight: 600;
    color: #2c3e50;
}

@keyframes tableAnimation {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* دانشکده‌ها */
.uni-faculties-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-faculties-title {
    font-size: 1.8rem;
    color: #4b2e83;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.uni-faculties-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    max-width: 1000px;
    margin: 0 auto;
}

.uni-faculty-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    text-align: center;
    border: 1px solid #e0e0e0;
    background: linear-gradient(135deg, #ececec, #ffffff);
    overflow: hidden;
    position: relative;
}

.uni-faculty-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.2);
}

.uni-faculty-card-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
}

.uni-faculty-card-text {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.6;
}

.uni-faculty-card .uni-faculty-icon {
    font-size: 3rem;
    color: #4b2e83;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
}

.uni-faculty-card:hover .uni-faculty-icon {
    transform: rotate(15deg);
}

/* بخش اخبار و رویدادها */
.uni-news-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-news-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-news-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr); /* دو ستونه */
    gap: 1.5rem;
    max-width: 1000px;
    margin: 0 auto;
  }

.uni-news-card {
    background-color: #f1f4f7;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-news-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-news-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-news-card-date {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 1rem;
}

.uni-news-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش مقالات تحقیقاتی */
.uni-research-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-research-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-research-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-research-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-research-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-research-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-research-card-date {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 1rem;
}

.uni-research-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش همکاری‌های بین‌المللی */
/* --- استایل‌های به‌روز شده برای بخش همکاری‌های بین‌المللی --- */
.uni-collaboration-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-collaboration-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-collaboration-item {
    position: relative;
    height: 240px; /* ارتفاع ثابت */
    border-radius: 16px;
    padding: 1.5rem 1rem;
    font-size: 1rem;
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    justify-content: center; /* مرکز‌چین عمودی */
    align-items: center;     /* مرکز‌چین افقی */
    text-align: center;
    background-size: cover;
    background-position: center;
  }
  

.uni-collaboration-item {
    position: relative;
    border-radius: 16px;
    padding: 1.5rem 1rem;
    font-size: 1rem;
    color: #ffffff;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.08);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    overflow: hidden;
}

/* افکت overlay برای ایجاد حالت مینیمال و خوانا */
.uni-collaboration-item::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.4);
    pointer-events: none;
}

.uni-collaboration-item:hover {
    transform: translateY(-5px) scale(1.02);
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.12);
}

.uni-collaboration-item a {
    position: relative;
    z-index: 1;
    text-decoration: none;
    font-weight: 600;
    color: #ffffff;
}

.uni-collaboration-ranking {
    position: relative;
    z-index: 1;
    margin-top: 0.5rem;
    font-size: 0.9rem;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 0.3rem;
}

/* Media query برای دستگاه‌های موبایل (نمایش دو ستونه) */
@media screen and (max-width: 768px) {
    .uni-collaboration-grid {
        grid-template-columns: repeat(2, 1fr);
    }
}


/* بخش کارآفرینی و فرصت‌های شغلی */
.uni-entrepreneurship-section {
    padding: 4rem 2rem;
    background-color: #f4f7fa;
    text-align: center;
}

.uni-entrepreneurship-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-entrepreneurship-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-entrepreneurship-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-entrepreneurship-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-entrepreneurship-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-entrepreneurship-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش سوالات متداول (FAQ) */
.uni-faq-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-faq-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-faq-grid {
    max-width: 900px;
    margin: 0 auto;
}

.uni-faq-card {
    background-color: #f1f4f7;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.uni-faq-card:hover {
    background-color: #ececec;
    transform: translateY(-4px);
}

.uni-faq-question {
    font-size: 1.1rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-faq-answer {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش اعلان ویژه بورسیه */
/* بخش اعلان ویژه بورسیه */
.uni-announcement-section {
    position: relative;
    padding: 4rem 2rem;
    text-align: center;
    margin: 4rem auto;
    border-radius: 16px;
    overflow: hidden;
    background: linear-gradient(180deg, rgba(0,0,0,0.6), rgba(0,0,0,0.9));
    box-shadow: 0 0 60px rgba(255, 215, 0, 0.2);
    max-width: 1000px;
    animation: fadeInUp 1s ease-in-out;
  }
  
  .announcement-bg-overlay {
    position: absolute;
    inset: 0;
    background-size: cover;
    background-position: center;
    filter: brightness(0.5) blur(6px);
    z-index: 0;
    transition: filter 0.5s ease;
  }
  
  .announcement-content {
    position: relative;
    z-index: 1;
    backdrop-filter: blur(3px);
  }
  
  .uni-announcement-title {
    font-size: 2.5rem;
    font-weight: 800;
    color: #f0c040;
    text-shadow: 1px 1px 4px rgba(0,0,0,0.8);
    margin-bottom: 1.2rem;
    letter-spacing: 0.5px;
  }
  
  .uni-announcement-text {
    font-size: 1.2rem;
    color: #ffffff;
    line-height: 1.8;
    max-width: 820px;
    margin: 0 auto;
    padding: 0 1rem;
    text-shadow: 0 1px 3px rgba(0,0,0,0.5);
  }
  
  /* انیمیشن نرم برای نمایش */
  @keyframes fadeInUp {
    from {
      opacity: 0;
      transform: translateY(40px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }
  
  /* ریسپانسیو برای موبایل */
  @media (max-width: 600px) {
    .uni-announcement-title {
      font-size: 1.6rem;
    }
    .uni-announcement-text {
      font-size: 1rem;
    }
  }
  


/* بخش اطلاعات و خدمات مالی */
.uni-financial-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-financial-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-financial-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-financial-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-financial-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-financial-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-financial-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* Media Queries مرتبط با بخش میانی */
@media screen and (max-width: 768px) {
    .uni-tuition-table {
        width: 100%;
    }
    .uni-faculties-grid,
    .uni-news-grid,
    .uni-research-grid,
    .uni-financial-grid,
    .uni-entrepreneurship-grid {
        grid-template-columns: 1fr;
    }
    .uni-collaboration-grid {
        display: grid;
        grid-template-columns: repeat(auto-fit, minmax(220px, 1fr));
        gap: 1rem;
        max-width: 1100px;
        margin: 0 auto;
      }
}
@media screen and (max-width: 768px) {
    .uni-collaboration-grid {
      grid-template-columns: repeat(2, 1fr);
    }
    .uni-news-grid {
        grid-template-columns: 1fr;
      }
  }
  
@media screen and (max-width: 480px) {
    .uni-tuition-title,
    .uni-news-title,
    .uni-research-title,
    .uni-collaboration-title,
    .uni-entrepreneurship-title,
    .uni-faq-title,
    .uni-financial-title {
        font-size: 1.6rem;
    }
}

/* تنظیمات کلی صفحه */
.dorm-container {
  width: 100%;
  min-height: 100vh;
  padding: 60px 20px 50px;
  box-sizing: border-box;
  text-align: center;
  background: #fff;
  position: relative;
}

/* بخش انیمیشن */
.dorm-animation-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

/* هدر صفحه - بدون پس‌زمینه، حاشیه، گردی یا سایه */
.dorm-header h2 {
  font-size: 36px;
  margin: 50px auto 30px;
  color: #4B0082;
  padding: 18px 50px;
  display: inline-block;
  letter-spacing: 1px;
  border-bottom: 2px solid #660c84; /* خط خلاقانه به عنوان تفکیک */
}

/* بخش اطلاعات */
.dorm-info {
  background: #fff;
  padding: 30px 40px;
  margin: 20px auto;
  max-width: 900px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}
.dorm-description {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
  color: #333;
}

/* استایل جدول خوابگاه‌ها */
.dorm-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto;
}
.dorm-table th,
.dorm-table td {
  padding: 15px;
  border: 1px solid #ddd;
  text-align: left;
}
.dorm-table th {
  background: linear-gradient(135deg, #4B0082, #7C4DFF);
  color: #fff;
  font-size: 18px;
}
.dorm-table td {
  font-size: 16px;
  color: #333;
}

/* استایل برای نمایش نام خوابگاه همراه تصویر */
.dorm-name-content {
  display: flex;
  align-items: center;
  gap: 10px;
}
.dorm-image {
  width: 50px;
  height: 50px;
  background-size: cover;
  background-position: center;
  border-radius: 4px;
  border: 2px solid #ccc; /* حاشیه نرم به تصویر */
}

/* تنظیمات پای چارت */
.dorm-chart-container {
  margin-top: 40px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  padding: 20px;
  background: #f8f8f8; /* پس‌زمینه خنثی برای نمودار */
  border-radius: 10px;
}

/* تنظیمات متون فارسی */
.dorm-persian-text {
  text-align: right;
  direction: rtl;
}
.dorm-persian-text table {
  direction: rtl;
}
.dorm-persian-text th,
.dorm-persian-text td {
  text-align: right;
}

/* واکنش‌گرایی برای موبایل */
@media (max-width: 768px) {
  .dorm-container {
    padding: 40px 10px 30px;
  }
  .dorm-header h2 {
    font-size: 28px;
    padding: 14px 20px;
    margin-top: 30px;
    border-bottom: none; /* حذف خط تفکیک در موبایل برای سادگی */
  }
  .dorm-info {
    padding: 20px;
    margin: 10px auto;
  }
  .dorm-description {
    font-size: 16px;
  }
  .dorm-table th,
  .dorm-table td {
    padding: 10px;
    font-size: 14px;
  }
  .dorm-name-content {
    gap: 8px;
  }
  .dorm-image {
    width: 40px;
    height: 40px;
  }
  .dorm-chart-container {
    padding: 10px;
    max-width: 90%;
  }
}

/* واکنش‌گرایی برای دستگاه‌های بسیار کوچک */
@media (max-width: 480px) {
  .dorm-header h2 {
    font-size: 24px;
    padding: 10px 15px;
  }
  .dorm-info {
    padding: 15px;
  }
  .dorm-description {
    font-size: 14px;
  }
  .dorm-table th,
  .dorm-table td {
    padding: 8px;
    font-size: 12px;
  }
}

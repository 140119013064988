@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Playfair+Display:wght@400;700&display=swap');

/* تنظیمات پایه برای html و body */
html, body {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  font-family: 'Playfair Display', Tahoma, Geneva, Verdana, sans-serif;
  background: #f9f9f9;
  color: #333;
}

/* تنظیمات کلی برای صفحه اجاره خانه */
.house-rental-container {
  width: 100%;
  min-height: 100vh;
  padding: 60px 20px 50px;
  box-sizing: border-box;
  text-align: center;
  position: relative;
}

/* بخش انیمیشن */
.animation-container {
  margin-top: 80px;
  display: flex;
  justify-content: center;
}

/* هدر صفحه - حذف کادر مربوطه */
.rental-header {
  margin-bottom: 30px;
}

.rental-header h2 {
  font-size: 36px;
  color: #4B0082; /* استفاده از رنگ اولیه برای متن */
  font-family: 'Lobster', cursive;
  /* حذف پس‌زمینه، پدینگ، شعاع حاشیه، سایه و افکت متن */
}

/* بخش اطلاعات */
.rental-info {
  background: #fff;
  padding: 30px;
  margin: 20px auto;
  max-width: 900px;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  text-align: left;
}

/* توضیحات اولیه */
.rental-info .description {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 20px;
}

/* سرتیترها */
.rental-info h3 {
  font-size: 24px;
  margin: 30px 0 15px;
  color: #4B0082;
  position: relative;
}


/* استایل لیست‌ها */
.rental-info ul {
  list-style: disc;
  margin: 0 0 20px 20px;
  padding: 0;
  color: #333;
}

.rental-info ul li {
  font-size: 18px;
  line-height: 1.8;
  margin-bottom: 10px;
}

/* متن پایانی */
.rental-info .final-note {
  font-size: 18px;
  line-height: 1.8;
  margin-top: 20px;
  color: #333;
}

/* قوانین اختصاصی برای متون فارسی */
.persian-text {
  text-align: right;
  direction: rtl;
}

.persian-text p,
.persian-text h3,
.persian-text li {
  text-align: right;
}

.persian-text ul {
  list-style-position: inside;
  padding: 0;
  margin: 0 0 20px 0;
  text-align: right;
}

/* تنظیمات واکنش‌گرا برای موبایل */
@media (max-width: 768px) {
  .rental-header h2 {
    font-size: 28px;
  }
  
  .rental-info {
    padding: 20px;
  }
  
  .rental-info .description,
  .rental-info ul li,
  .rental-info .final-note {
    font-size: 16px;
  }
  
  .rental-info h3 {
    font-size: 20px;
  }
}

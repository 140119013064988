/* --- بخش کانتینر اصلی --- */
.aboutus-container {
  position: relative;
  font-family: "Poppins", sans-serif;
  padding-top: 80px;
  min-height: 100vh;
  color: var(--dark-color);
  background-color: #ffffff; /* بکگراند سفید */
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  overflow: hidden;
  padding-bottom: 50px;
}

/* --- بخش انیمیشن Lottie --- */
.about-animation-container {
  margin: 40px auto;
  max-width: 800px;
  overflow: visible;
  border-radius: 20px; /* گوشه‌های انیمیشن گرد */
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.2); /* سایه برای انیمیشن */
}

/* --- بخش اطلاعات درباره ما --- */
.about-content {
  max-width: 900px;
  margin: 40px auto;
  padding: 40px;
  background: linear-gradient(135deg, rgba(161, 75, 192, 0.2), rgba(255, 255, 255, 0.9));
  border-radius: 15px;
  box-shadow: 0 15px 50px rgba(0, 0, 0, 0.2); /* سایه سنگین‌تر */
  text-align: justify;
  backdrop-filter: blur(15px); /* افکت تاری */
  border: 2px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

/* اضافه کردن افکت نوری در اطراف */
.about-content::before {
  content: '';
  position: absolute;
  top: -10px;
  left: -10px;
  right: -10px;
  bottom: -10px;
  background: rgba(255, 255, 255, 0.1); /* نور خفیف در اطراف */
  border-radius: 15px;
  filter: blur(10px);
  z-index: -1;
}

.about-content h1 {
  font-size: 3rem;
  margin-bottom: 25px;
  color: #520e6c;
  text-align: center;
  text-transform: uppercase;
  text-shadow: 4px 4px 12px rgba(0, 0, 0, 0.3);
  letter-spacing: 2px;
  font-weight: 700;
  line-height: 1.4;
}

/* --- راست‌چین و چپ‌چین برای زبان‌ها --- */
.about-content.rtl {
  direction: rtl;
  text-align: right;
}

.about-content.ltr {
  direction: ltr;
  text-align: left;
}


/* --- انیمیشن گرادیانت پس‌زمینه --- */
@keyframes gradientMove {
  0% {
    background-position: 0% 50%;
  }
  50% {
    background-position: 100% 50%;
  }
  100% {
    background-position: 0% 50%;
  }
}



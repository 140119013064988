.extera-toolbar-container {
  position: fixed;  
  top: 0;
  left: 0;
  width: 100%;
  background: #ffffff;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 16px;
  box-shadow: 0 4px 14px rgba(75, 0, 130, 0.15); /* سایه بنفش */
  z-index: 9999;
}

/* آیکون‌های چپ و راست */
.extera-toolbar-left,
.extera-toolbar-right {
  display: flex;
  gap: 8px;
}

.extera-toolbar-left a,
.extera-toolbar-right a {
  background: none;
  border: none;
  color: #6A1B9A; /* بنفش رسمی */
  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  cursor: pointer;
  transition: color 0.3s, transform 0.3s;
  padding: 6px;
  border-radius: 50%;
}

.extera-toolbar-left a:hover,
.extera-toolbar-right a:hover {
  color: #8E24AA; /* بنفش ملایم هنگام هاور */
  transform: scale(1.1);
}

/* عنوان مرکز */
.extera-toolbar-center {
  flex: 1;
  display: flex;
  justify-content: center;
}

.uni-manager-title {
  font-size: 38px;
  font-family: 'Lobster', cursive;
  background: linear-gradient(90deg, #5E35B1, #8E24AA, #4A148C); /* گرادیان سلطنتی */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 6px 16px rgba(110, 0, 180, 0.25);
  letter-spacing: 1.5px;
  animation: floatTitle 3s ease-in-out infinite;
  transition: transform 0.3s ease;
  cursor: default;
}
.uni-manager-title:hover {
  transform: scale(1.05) rotate(-1deg);
}

@keyframes floatTitle {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-6px);
  }
}

/* دکمه‌های زبان */
.lang-group {
  display: flex;
  gap: 8px;
}

.lang-btn {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background 0.3s, transform 0.3s, border-color 0.3s;
}

.lang-btn:hover {
  background: #F3E5F5;
  border-color: #CE93D8;
  transform: scale(1.05);
}

.lang-abbr {
  font-size: 16px;
  font-weight: bold;
  color: #6A1B9A;
  font-family: 'Lobster', cursive;
}

/* آیکون‌ها */
.icon {
  font-size: 1em;
}

/* منوی کشویی */
.dropdown-container {
  position: relative;
}

.dropdown-toggle {
  background: none;
  border: none;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: background 0.3s, transform 0.3s;
}

.dropdown-toggle:hover {
  background: #F3E5F5;
  transform: scale(1.05);
}

.dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #ffffff;
  border: 1px solid #D1C4E9;
  box-shadow: 0 4px 12px rgba(75, 0, 130, 0.15);
  border-radius: 6px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10000;
}

/* نمایش در دسکتاپ */
.desktop-only {
  display: flex;
}

/* نمایش در موبایل */
.mobile-only {
  display: none;
}

@media (max-width: 768px) {
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: flex;
  }
}

/* Contact.css */

.contact-page {
  font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif;
  text-align: center;
  padding: 3rem 1rem;
  background: #fff; /* پس‌زمینه کل صفحه سفید */
  color: #333;
}

/* جهت‌دهی برای حالت‌های راست‌به‌چپ و چپ‌به‌راست */
.contact-page.ltr {
  direction: ltr;
}
.contact-page.rtl {
  direction: rtl;
}

.language-switcher {
  margin-bottom: 2rem;
}

.language-switcher button {
  margin: 0 0.5rem;
  padding: 0.6rem 1.2rem;
  border: none;
  background-color: #4A148C;
  color: #fff;
  cursor: pointer;
  border-radius: 20px;
  font-weight: bold;
  transition: background-color 0.4s ease, transform 0.3s ease;
}

.language-switcher button:hover {
  background-color: #6A1B9A;
  transform: scale(1.05);
}

.contact-header h1 {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
  letter-spacing: 1px;
  color: #4A148C;
  text-align: center; /* اضافه شده برای مرکزیت */
}

.contact-header p {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
  line-height: 1.6;
  color: #555;
}

.animation-container {
  margin: 0 auto 2rem auto;
  max-width: 320px;
}

.contact-methods {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.5rem;
}

/* کادرهای تماس با پس‌زمینه گرادیان بنفش پویا */
.contact-item {
  display: flex;
  align-items: center;
  background: linear-gradient(135deg, #4B0082, #7C4DFF); /* Royal & Vibrant */
  width: 90%;
  max-width: 500px;
  padding: 1rem 1.5rem;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0,0,0,0.15);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background 0.5s ease;
}

.contact-item:hover {
  transform: translateY(-8px);
  box-shadow: 0 6px 16px rgba(0,0,0,0.2);
  background: linear-gradient(135deg, #7C4DFF, #4B0082); /* Hover Reverse Gradient */
}

.contact-item .icon {
  font-size: 2.2rem;
  margin-right: 1rem;
  color: #fff;
}

/* تغییرات اعمال‌شده در .contact-info */
.contact-info {
  flex: 1;
  text-align: left !important;
  direction: ltr !important;
}

.contact-info h2 {
  margin: 0;
  font-size: 1.3rem;
  color: #fff;
}

.contact-info a {
  text-decoration: none;
  color: #fff;
  font-size: 1rem;
  transition: color 0.3s ease;
}

.contact-info a:hover {
  color: #e1bee7;
}

/* استایل برای زبان‌های چپ‌به‌راست (LTR) */
.ltr {
    direction: ltr !important;
    text-align: left !important;
}

/* استایل کلی صفحه (بخش بالا) */
.uni-container {
    width: 100%;
    font-family: "Vazirmatn", sans-serif;
    direction: rtl;
    background-color: #f4f4f4; /* رنگ پس‌زمینه نقره‌ای مات */
    color: #333;
    line-height: 1.7;
    margin-top: 5rem;
    padding: 2rem;
}

/* بنر مینیمال */
.uni-hero-minimal {
    width: 100%;
    height: 55vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #f0f0f0;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    position: relative;
}

.uni-hero-image-minimal {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.85) contrast(1.1);
    border-radius: 10px;
    transition: filter 0.3s ease;
}

.uni-hero-minimal:hover .uni-hero-image-minimal {
    filter: brightness(1) contrast(1);
}

/* اطلاعات کلیدی */
.uni-info-section {
    padding: 4rem 2rem;
    background-color: #ececec;
    border-bottom: 2px solid #e0e0e0;
    text-align: center;
}

.uni-info-title {
    font-size: 2rem;
    color: #4b2e83;
    font-weight: 700;
    margin-bottom: 3rem;
}

.uni-info-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.2rem;
    max-width: 900px;
    margin: 0 auto;
}

.uni-info-card,
.uni-admission-card {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    text-align: center;
    background: linear-gradient(135deg, #ececec, #ffffff);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
}

.uni-info-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.2);
}

.uni-info-card h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 1rem;
}

.uni-info-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* کارت‌های اطلاعات تکمیلی */
.uni-detail-icon-block {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
    background-color: #f1f4f7;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    direction: rtl;
    text-align: right;
}

.uni-detail-icon-block:hover {
    transform: translateY(-6px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.uni-detail-icon {
    font-size: 2.5rem;
    color: #4b2e83;
}

.uni-detail-icon-text h3 {
    font-size: 1.2rem;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-detail-icon-text p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

/* شرایط پذیرش */
.uni-admission-section {
    padding: 4rem 2rem;
    background-color: #f4f7fa;
}

.uni-admission-title {
    font-size: 2.2rem;
    color: #4b2e83;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 700;
}

.uni-admission-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    max-width: 900px;
    margin: 0 auto;
}

.uni-admission-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.uni-admission-card:hover {
    transform: translateY(-8px);
    background-color: #f9f9f9;
}

.uni-admission-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.8rem;
}

.uni-admission-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش گالری تصاویر */
.uni-gallery-section {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    text-align: center;
}

.uni-gallery-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-gallery-card {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin: 0 10px;
}

.uni-gallery-card img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.uni-gallery-card img:hover {
    transform: scale(1.05);
}

/* تنظیمات استایل اسلایدر (برای فعال شدن قابلیت swipe) */
.slick-slide {
    padding: 10px;
}

/* Media Queries مرتبط با بخش بالا */
@media screen and (max-width: 768px) {
    .uni-info-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.2rem;
    }
    .uni-gallery-card img {
        height: 350px;
    }
}

@media screen and (max-width: 480px) {
    .uni-container {
        padding: 1rem;
        margin-top: 2rem;
    }
    .uni-hero-minimal {
        height: 40vh;
    }
    .uni-info-title,
    .uni-admission-title {
        font-size: 1.6rem;
    }
    .uni-info-card h3,
    .uni-faq-question {
        font-size: 1rem;
    }
    .uni-info-card p,
    .uni-faq-answer,
    .uni-admission-card-text {
        font-size: 0.9rem;
    }
    .uni-gallery-card img {
        height: 350px;
    }
}

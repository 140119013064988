/* نوار بالای صفحه */
.tool-home-navbar {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 20px 40px;
  background: #ffffff;
  box-shadow: 0 6px 20px rgba(75, 0, 130, 0.15); /* سایه بنفش ملایم */
  z-index: 1000;
  color: #3E006D;
}

/* آیکون‌های سمت راست و چپ */
.tool-toolbar-right,
.tool-toolbar-left {
  display: flex;
  align-items: center;
  gap: 16px;
}

.tool-toolbar-right {
  position: absolute;
  right: 24px;
}

.tool-toolbar-left {
  position: absolute;
  left: 24px;
}

/* عنوان وسط با افکت گرادیانی بنفش سلطنتی */
.tool-toolbar-center {
  font-size: 38px;
  font-family: 'Lobster', cursive;
  background: linear-gradient(90deg, #5E35B1, #8E24AA, #4A148C); /* بنفش خلاقانه */
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  text-shadow: 0 6px 16px rgba(110, 0, 180, 0.3);
  letter-spacing: 1.5px;
  animation: floatTitle 3s ease-in-out infinite;
  transition: transform 0.3s ease;
  cursor: default;
}

@keyframes floatTitle {
  0%, 100% {
    transform: translateY(0px);
  }
  50% {
    transform: translateY(-6px);
  }
}

/* دکمه‌های نوار بالا */
.tool-home-navbar button {
  background: transparent;
  border: none;
  color: #6A1B9A;
  font-size: 20px;
  cursor: pointer;
  transition: transform 0.3s, color 0.3s;
}

.tool-home-navbar button:hover {
  color: #9C27B0; /* بنفش روشن‌تر هنگام هاور */
  transform: scale(1.1);
}

/* دکمه‌های تغییر زبان */
.tool-lang-btn {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  transition: background 0.3s, transform 0.3s, border-color 0.3s;
}

.tool-lang-btn:hover {
  background: #F3E5F5;
  border-color: #CE93D8;
  transform: scale(1.05);
}

.tool-lang-abbr {
  font-size: 16px;
  font-weight: bold;
  color: #6A1B9A;
  font-family: 'Lobster', cursive;
}

/* پس‌زمینه پوششی (backdrop) */
.tool-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  backdrop-filter: blur(5px);
  z-index: 1000;
}

/* منوی کشویی اصلی */
.tool-dropdown-menu {
  position: fixed;
  top: 65px;
  right: 24px;
  background: #ffffff;
  border-radius: 10px;
  box-shadow: 0 12px 30px rgba(75, 0, 130, 0.2); /* سایه بنفش */
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1100;
  animation: fadeIn 0.3s ease-in-out;
  min-width: 300px;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* دکمه‌های منوی کشویی */
.tool-dropdown-menu button,
.tool-nested-dropdown button,
.tool-nested-dropdown-content button {
  padding: 16px 24px;
  text-align: center;
  background: none;
  border: none;
  color: #4A148C;
  font-size: 16px;
  transition: background 0.2s, color 0.2s;
  cursor: pointer;
}

.tool-dropdown-menu button:hover,
.tool-nested-dropdown button:hover,
.tool-nested-dropdown-content button:hover {
  background: #F3E5F5;
  color: #7C4DFF;
}

/* منوی تو در تو */
.tool-nested-dropdown {
  position: relative;
  width: 100%;
}

.tool-nested-dropdown-content {
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-left: 2px solid #E1BEE7;
  background: #FAF6FF;
  width: 100%;
  text-align: center;
  padding: 0;
}

.tool-nested-dropdown.open .tool-nested-dropdown-content {
  display: flex;
}

/* بخش‌های زبان برای دسکتاپ و موبایل */
.tool-lang-desktop {
  display: flex;
  gap: 8px;
}

.tool-lang-mobile {
  display: none;
}

/* منوی کشویی زبان (برای موبایل) */
.tool-dropdown-container {
  position: relative;
}

.tool-lang-dropdown-toggle {
  background: none;
  border: 2px solid transparent;
  cursor: pointer;
  padding: 6px 10px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 4px;
  transition: background 0.3s, transform 0.3s;
}

.tool-lang-dropdown-toggle:hover {
  background: #F3E5F5;
  transform: scale(1.05);
}

.tool-lang-dropdown-menu {
  position: absolute;
  top: 100%;
  right: 0;
  background: #ffffff;
  border: 1px solid #D1C4E9;
  box-shadow: 0 4px 12px rgba(75, 0, 130, 0.15);
  border-radius: 6px;
  padding: 6px;
  display: flex;
  flex-direction: column;
  gap: 6px;
  z-index: 10000;
}
.submenu-icon {
  margin-left: 4px;
  font-size: 1.2em;
  transition: transform 0.3s;
}

.tool-nested-dropdown.open .submenu-icon {
  transform: rotate(90deg);
}

/* واکنش‌گرا */
@media (max-width: 768px) {
  .tool-toolbar-left,
  .tool-toolbar-right {
    gap: 12px;
  }

  .tool-toolbar-center {
    font-size: 22px;
  }

  .tool-dropdown-menu {
    right: 5%;
    top: 65px;
  }
  /* نمایش نسخه موبایل دکمه‌های زبان */
  .tool-lang-desktop {
    display: none;
  }
  .tool-lang-mobile {
    display: flex;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Playfair+Display:wght@400;700&display=swap');

/* تنظیمات پایه برای html و body */
html, body {
    width: 100%;
    height: 100%;
    margin: 0;
    padding: 0;
    font-family: 'Playfair Display', serif;
    background: #f9f9f9;
    color: #333;
}

/* تنظیمات کلی برای صفحه اقامت دانشجویی */
.student-residency-container {
    width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 60px 20px 50px;
    position: relative;
    box-sizing: border-box;
}

/* انیمیشن */
.student-animation-container {
    margin-top: 80px;
    display: flex;
    justify-content: center;
}

/* هدر صفحه (عنوان اصلی) */
.student-residency-header {
    font-size: 30px;
    font-weight: bold;
    margin-top: 50px;
    margin-bottom: 30px;
    color: #4B0082; /* بنفش رسمی */
    background: none;
    padding: 0;
    border-radius: 0;
    box-shadow: none;
    text-shadow: none;
    letter-spacing: 1px;
    font-family: 'Lobster', cursive;
    text-align: center;
}

/* بخش توضیحات (اطلاعات اقامت) */
.student-residency-info {
    background: #fff;
    padding: 30px;
    margin: 20px auto;
    max-width: 900px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(75, 0, 130, 0.1); /* سایه با تن بنفش */
    text-align: left;
    border: 1px solid #7C4DFF; /* خط دور بنفش براق */
}

.student-residency-info p {
    font-size: 18px;
    line-height: 1.8;
    color: #333;
    margin-bottom: 20px;
}

.student-residency-info h3 {
    font-size: 24px;
    margin: 30px 0 15px;
    color: #4B0082;
    position: relative;
    font-family: 'Lobster', cursive;
}

.student-residency-info ul {
    list-style: disc;
    margin: 0 auto 20px;
    padding: 0;
    display: inline-block;
    text-align: left;
    color: #333;
}

.student-residency-info ul li {
    font-size: 18px;
    line-height: 1.8;
    margin-bottom: 10px;
}

/* قوانین اختصاصی برای متون فارسی */
.student-persian-text {
    text-align: right !important;
    direction: rtl;
}

.student-persian-text p,
.student-persian-text h3,
.student-persian-text li {
    text-align: right;
}

.student-persian-text ul {
    list-style-position: inside;
    padding: 0;
    margin: 0 auto 20px;
    text-align: right;
}

/* فوتر صفحه */
.student-residency-footer {
    text-align: left;
    margin-top: 40px;
    padding-top: 20px;
    border-top: 2px solid #eee;
}

.student-residency-footer p {
    font-size: 18px;
    color: #7C4DFF; /* آبی بنفش حرفه‌ای */
    font-weight: bold;
}

/* تنظیمات واکنش‌گرا برای موبایل */
@media (max-width: 768px) {
    .student-residency-container {
        padding: 40px 15px 30px;
    }

    .student-residency-header {
        font-size: 28px;
        padding: 14px 30px;
        margin-top: 20px;
    }

    .student-residency-info p,
    .student-residency-info ul li {
        font-size: 16px;
    }

    .student-residency-info h3 {
        font-size: 20px;
    }

    .student-residency-footer p {
        font-size: 16px;
    }
}

/* استایل برای زبان‌های چپ‌به‌راست (LTR) */
.ltr {
    direction: ltr !important;
    text-align: left !important;
}

/* استایل کلی صفحه */
.uni-container {
    width: 100%;
    font-family: "Vazirmatn", sans-serif;
    direction: rtl;
    background-color: #f4f4f4; /* رنگ پس‌زمینه نقره‌ای مات */
    color: #333;
    line-height: 1.7;
    margin-top: 5rem;
    padding: 2rem;
}

/* بنر مینیمال */
.uni-hero-minimal {
    width: 100%;
    height: 55vh;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    border-bottom: 5px solid #f0f0f0;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.1);
    position: relative;
}

.uni-hero-image-minimal {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    filter: brightness(0.85) contrast(1.1);
    border-radius: 10px;
    transition: filter 0.3s ease;
}

.uni-hero-minimal:hover .uni-hero-image-minimal {
    filter: brightness(1) contrast(1);
}

/* اطلاعات کلیدی */
.uni-info-section {
    padding: 4rem 2rem;
    background-color: #ececec;
    border-bottom: 2px solid #e0e0e0;
    text-align: center;
}

.uni-info-title {
    font-size: 2rem;
    color: #4b2e83;
    font-weight: 700;
    margin-bottom: 3rem;
}

.uni-info-grid {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 0.2rem;
    max-width: 900px;
    margin: 0 auto;
}

.uni-info-card,
.uni-admission-card,
.uni-faculty-card {
    background-color: #ffffff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 16px;
    padding: 2rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    text-align: center;
    background: linear-gradient(135deg, #ececec, #ffffff);
    box-shadow: 0px 6px 16px rgba(0, 0, 0, 0.1);
}

.uni-info-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.2);
}

.uni-info-card h3 {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 1rem;
}

.uni-info-card p {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* کارت‌های اطلاعات تکمیلی */
.uni-detail-icon-block {
    display: flex;
    align-items: center;
    gap: 1.5rem;
    padding: 1.5rem;
    background-color: #f1f4f7;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    margin-bottom: 1.5rem;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    direction: rtl;
    text-align: right;
}

.uni-detail-icon-block:hover {
    transform: translateY(-6px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

.uni-detail-icon {
    font-size: 2.5rem;
    color: #4b2e83;
}

.uni-detail-icon-text h3 {
    font-size: 1.2rem;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-detail-icon-text p {
    font-size: 1rem;
    color: #333;
    line-height: 1.6;
}

/* شرایط پذیرش */
.uni-admission-section {
    padding: 4rem 2rem;
    background-color: #f4f7fa;
}

.uni-admission-title {
    font-size: 2.2rem;
    color: #4b2e83;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 700;
}

.uni-admission-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 1rem;
    max-width: 900px;
    margin: 0 auto;
}

.uni-admission-card {
    background-color: #fff;
    border: 1px solid #e0e0e0;
    border-radius: 12px;
    padding: 2rem;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: all 0.3s ease;
}

.uni-admission-card:hover {
    transform: translateY(-8px);
    background-color: #f9f9f9;
}

.uni-admission-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.8rem;
}

.uni-admission-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* جدول شهریه */
.uni-tuition-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-tuition-title {
    font-size: 2rem;
    color: #4b2e83;
    text-align: center;
    margin-bottom: 2.5rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.uni-tuition-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    overflow: hidden;
    transform: translateY(10px);
    animation: tableAnimation 0.5s ease-in-out;
}

.uni-tuition-table th,
.uni-tuition-table td {
    padding: 1.5rem;
    text-align: center;
    vertical-align: middle;
    border-bottom: 2px solid #e0e0e0;
    font-size: 1rem;
    color: #4a4a4a;
    font-family: 'Open Sans', sans-serif;
}

.uni-tuition-table th {
    background-color: #4b2e83;
    color: #ffffff;
    font-size: 1.1rem;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.uni-tuition-table tr:hover {
    background-color: #f0f7ff;
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
}

.uni-tuition-table td {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

.uni-tuition-table td:first-child {
    font-weight: 600;
    color: #2c3e50;
}

@keyframes tableAnimation {
    0% {
        opacity: 0;
        transform: translateY(30px);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}

/* دانشکده‌ها */
.uni-faculties-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-faculties-title {
    font-size: 1.8rem;
    color: #4b2e83;
    text-align: center;
    margin-bottom: 2rem;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
}

.uni-faculties-grid {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.5rem;
    max-width: 1000px;
    margin: 0 auto;
}

.uni-faculty-card {
    background-color: #ffffff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s ease, box-shadow 0.3s ease, border-color 0.3s ease;
    text-align: center;
    border: 1px solid #e0e0e0;
    background: linear-gradient(135deg, #ececec, #ffffff);
    overflow: hidden;
    position: relative;
}

.uni-faculty-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.15);
    border-color: rgba(0, 0, 0, 0.2);
}

.uni-faculty-card-title {
    font-size: 1.4rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 1rem;
    font-family: 'Open Sans', sans-serif;
}

.uni-faculty-card-text {
    font-size: 0.9rem;
    color: #666;
    line-height: 1.6;
}

.uni-faculty-card .uni-faculty-icon {
    font-size: 3rem;
    color: #4b2e83;
    margin-bottom: 1rem;
    transition: transform 0.3s ease;
}

.uni-faculty-card:hover .uni-faculty-icon {
    transform: rotate(15deg);
}

/* بخش گالری تصاویر */
.uni-gallery-section {
    padding: 4rem 2rem;
    background-color: #f9f9f9;
    text-align: center;
}

.uni-gallery-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

/* استایل جدید برای کارت‌های گالری مینیمال */
.uni-gallery-card {
    background-color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.12);
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    margin: 0 10px;
}

.uni-gallery-card img {
    width: 100%;
    height: 350px;
    object-fit: cover;
    transition: transform 0.3s ease;
}

.uni-gallery-card img:hover {
    transform: scale(1.05);
}

/* تنظیمات استایل اسلایدر (برای فعال شدن قابلیت swipe) */
.slick-slide {
    padding: 10px;
}

/* بخش اخبار و رویدادها */
.uni-news-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-news-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-news-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 2rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-news-card {
    background-color: #f1f4f7;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-news-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-news-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-news-card-date {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 1rem;
}

.uni-news-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش مقالات تحقیقاتی */
.uni-research-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-research-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-research-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-research-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-research-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-research-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-research-card-date {
    font-size: 0.9rem;
    color: #999;
    margin-bottom: 1rem;
}

.uni-research-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش همکاری‌های بین‌المللی */
.uni-collaboration-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-collaboration-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-collaboration-grid {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.5rem;
}

.uni-collaboration-item {
    background-color: #ececec;
    border-radius: 12px;
    padding: 1rem 2rem;
    font-size: 1rem;
    color: #4b2e83;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-collaboration-item:hover {
    transform: translateY(-4px);
    box-shadow: 0 8px 18px rgba(0, 0, 0, 0.1);
}

/* بخش کارآفرینی و فرصت‌های شغلی */
.uni-entrepreneurship-section {
    padding: 4rem 2rem;
    background-color: #f4f7fa;
    text-align: center;
}

.uni-entrepreneurship-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-entrepreneurship-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-entrepreneurship-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-entrepreneurship-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-entrepreneurship-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-entrepreneurship-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش سوالات متداول (FAQ) */
.uni-faq-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-faq-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-faq-grid {
    max-width: 900px;
    margin: 0 auto;
}

.uni-faq-card {
    background-color: #f1f4f7;
    border-radius: 12px;
    padding: 1.5rem;
    margin-bottom: 1rem;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
}

.uni-faq-card:hover {
    background-color: #ececec;
    transform: translateY(-4px);
}

.uni-faq-question {
    font-size: 1.1rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-faq-answer {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش اعلان ویژه بورسیه */
.uni-announcement-section {
    padding: 4rem 2rem;
    background-color: #fffbe6;
    text-align: center;
    border: 2px dashed #f0c040;
    margin-bottom: 4rem;
    border-radius: 12px;
}

.uni-announcement-title {
    font-size: 2rem;
    color: #d35400;
    font-weight: 700;
    margin-bottom: 1rem;
}

.uni-announcement-text {
    font-size: 1.1rem;
    color: #8e44ad;
    line-height: 1.6;
    max-width: 800px;
    margin: 0 auto;
}

/* بخش اطلاعات و خدمات مالی (Financial Aid and Scholarships) */
.uni-financial-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-financial-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 3rem;
    font-weight: 700;
}

.uni-financial-grid {
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    gap: 0.5rem;
    max-width: 1100px;
    margin: 0 auto;
}

.uni-financial-card {
    background-color: #fff;
    border-radius: 12px;
    padding: 1.5rem;
    box-shadow: 0 4px 12px rgba(0,0,0,0.05);
    transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.uni-financial-card:hover {
    transform: translateY(-8px);
    box-shadow: 0 8px 24px rgba(0,0,0,0.1);
}

.uni-financial-card-title {
    font-size: 1.2rem;
    font-weight: 600;
    color: #4b2e83;
    margin-bottom: 0.5rem;
}

.uni-financial-card-text {
    font-size: 1rem;
    color: #666;
    line-height: 1.6;
}

/* بخش رتبه‌بندی دانشگاه‌ها و مقایسه */
.uni-ranking-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-ranking-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 2rem;
    font-weight: 700;
}

.uni-ranking-table {
    width: 80%;
    margin: 0 auto;
    border-collapse: collapse;
    background-color: #ffffff;
    box-shadow: 0 10px 30px rgba(0,0,0,0.1);
    border-radius: 12px;
    overflow: hidden;
}

.uni-ranking-table th,
.uni-ranking-table td {
    padding: 1rem;
    text-align: center;
    border-bottom: 2px solid #e0e0e0;
    font-size: 1rem;
    color: #4a4a4a;
    font-family: 'Open Sans', sans-serif;
}

.uni-ranking-table th {
    background-color: #4b2e83;
    color: #ffffff;
    text-transform: uppercase;
    letter-spacing: 0.5px;
}

.uni-ranking-table tr:hover {
    background-color: #f0f7ff;
    transform: translateY(-5px);
}

/* بخش بررسی شهریه‌ها (Tuition Fee Calculator) */
.uni-calculator-section {
    padding: 4rem 2rem;
    background-color: #f9fbff;
    text-align: center;
}

.uni-calculator-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 2rem;
    font-weight: 700;
}

.uni-calculator-form {
    max-width: 500px;
    margin: 0 auto;
    display: flex;
    flex-direction: column;
    gap: 1rem;
}

.uni-calculator-form label {
    font-size: 1rem;
    color: #333;
}

.uni-calculator-form select {
    padding: 0.8rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    font-size: 1rem;
}

.uni-calculator-form button {
    padding: 0.8rem;
    background-color: #4b2e83;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    font-size: 1rem;
    transition: background-color 0.3s ease;
}

.uni-calculator-form button:hover {
    background-color: #3a216a;
}

.uni-calculator-result {
    margin-top: 1.5rem;
    font-size: 1.2rem;
    color: #4b2e83;
}

/* کادر نمایش نتیجه تبدیل ارزها در ماشین حساب */
.conversion-card {
    background-color: #fff;
    border: 2px solid #4b2e83;
    border-radius: 12px;
    padding: 1.5rem;
    margin-top: 1.5rem;
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.conversion-card h3 {
    font-size: 1.5rem;
    color: #4b2e83;
    margin-bottom: 1rem;
}

.conversion-card p {
    font-size: 1.1rem;
    color: #333;
    margin: 0.5rem 0;
}

.conversion-card hr {
    margin: 1rem 0;
    border: none;
    border-top: 1px solid #ccc;
}

/* بخش نقشه دانشگاه */
.uni-map-section {
    padding: 4rem 2rem;
    background-color: #fff;
    text-align: center;
}

.uni-map-title {
    font-size: 2rem;
    color: #4b2e83;
    margin-bottom: 2rem;
    font-weight: 700;
}

.uni-map-container iframe {
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
}

/* بهبود استایل برای موبایل */
@media screen and (max-width: 768px) {
    .uni-info-grid {
        grid-template-columns: repeat(2, 1fr);
        gap: 0.2rem;
    }
    .uni-tuition-table, 
    .uni-ranking-table {
        width: 100%;
    }
    .uni-gallery-grid, 
    .uni-news-grid, 
    .uni-research-grid, 
    .uni-financial-grid, 
    .uni-entrepreneurship-grid {
        grid-template-columns: 1fr;
    }
    .uni-collaboration-grid {
        flex-direction: column;
        align-items: center;
    }
}

@media screen and (max-width: 480px) {
    .uni-container {
        padding: 1rem;
        margin-top: 2rem;
    }
    .uni-hero-minimal {
        height: 40vh;
    }
    .uni-info-title, 
    .uni-admission-title, 
    .uni-tuition-title, 
    .uni-news-title, 
    .uni-research-title, 
    .uni-collaboration-title, 
    .uni-entrepreneurship-title, 
    .uni-faq-title, 
    .uni-financial-title, 
    .uni-ranking-title, 
    .uni-calculator-title, 
    .uni-map-title {
        font-size: 1.6rem;
    }
    .uni-info-card h3,
    .uni-faq-question {
        font-size: 1rem;
    }
    .uni-info-card p,
    .uni-faq-answer,
    .uni-admission-card-text {
        font-size: 0.9rem;
    }
    .uni-calculator-form select,
    .uni-calculator-form button {
        font-size: 1rem;
    }
    .uni-announcement-title {
        font-size: 1.8rem;
    }
    .uni-announcement-text {
        font-size: 1rem;
    }
    .uni-gallery-card img {
        height: 350px;
    }
}

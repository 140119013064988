html, body {
  margin: 0;
  padding: 0;
  overflow-x: hidden; /* در صورت نیاز */
  /* بقیه تنظیمات دلخواه */
}

/* تنظیمات کلی کانتینر اصلی */
.ozcup-universities-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  text-align: center;
  padding-bottom: 50px;
  font-family: sans-serif;
  margin-top: 60px; /* مقداری متناسب با ارتفاع Toolbar انتخاب کنید */
}

/* گزینه تغییر زبان (به صورت ثابت در بالا-چپ) */
.ozcup-language-selector {
  position: fixed; 
  top: 20px;       /* فاصله از بالا */
  left: 20px;      /* فاصله از چپ */
  display: flex;
  gap: 8px;
  z-index: 9999;   /* برای اطمینان از اینکه روی همه چیز قرار بگیرد */
  background: #fff; /* برای جداشدن از پس‌زمینه */
  padding: 5px 10px;
  border-radius: 8px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.ozcup-language-selector button {
  background: #fff;
  border: 2px solid #800080; /* تغییر رنگ به بنفش */
  padding: 8px 15px;
  cursor: pointer;
  transition: 0.3s;
  font-size: 14px;
  font-weight: bold;
  border-radius: 8px;
  color: #800080; /* تغییر رنگ به بنفش */
}

.ozcup-language-selector button:hover {
  background: #800080; /* تغییر رنگ به بنفش */
  color: #fff;
}

/* انیمیشن */
/* فاصله بیشتر تا زیر دکمه‌های زبان */
.ozcup-animation-container {
  margin-top: 120px;  /* می‌توانید این عدد را مطابق نیاز افزایش/کاهش دهید */
  display: flex;
  justify-content: center;
}

/* هدر دانشگاه‌های برتر استانبول */
.ozcup-main-header {
  font-size: 38px;
  font-weight: bold;
  margin: 40px auto 20px;
  color: #fff;
  background: linear-gradient(270deg, #800080, #4b0082, #9400D3);
  background-size: 600% 600%;
  -webkit-animation: GradientAnimation 10s ease infinite;
  -moz-animation: GradientAnimation 10s ease infinite;
  animation: GradientAnimation 10s ease infinite;
  padding: 15px 40px;
  border-radius: 12px;
  display: inline-block;
  text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.3);
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);
  letter-spacing: 1px;
}

@-webkit-keyframes GradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
@-moz-keyframes GradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}
@keyframes GradientAnimation {
  0% { background-position: 0% 50%; }
  50% { background-position: 100% 50%; }
  100% { background-position: 0% 50%; }
}

/* شبکه نمایش کارت‌های دانشگاه برای دستکتاپ */
.ozcup-universities-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 10px;
  margin-top: 30px;
  justify-items: center;
}

/* طراحی کارت‌های دانشگاه */
.ozcup-university-card {
  width: 100%;
  max-width: 320px;
  height: 420px;
  background: #fff;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  transition: transform 0.3s, box-shadow 0.3s;
  position: relative;
  cursor: pointer;
  /* افکت نمایان‌شدن اولیه */
  opacity: 0;
  transform: translateY(30px);
  animation: ozcup-fade-up 0.6s forwards;
}

/* انیمیشن ورود کارت‌ها از پایین به بالا */
@keyframes ozcup-fade-up {
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* هنگام هاور روی کارت در دسکتاپ */
.ozcup-university-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 12px 30px rgba(0, 0, 0, 0.25);
}

/* تصویر دانشگاه */
.ozcup-university-card .ozcup-university-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: opacity 0.4s;
  border: 3px solid #800080; /* حاشیه بنفش */
  box-shadow: 0 4px 10px rgba(128, 0, 128, 0.5); /* سایه بنفش */
}

/* اطلاعات کارت */
.ozcup-university-card .ozcup-university-info {
  position: absolute;
  inset: 0; /* top:0; right:0; bottom:0; left:0; */
  background: rgba(0, 0, 0, 0.85);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 20px;
  text-align: center;
  border-radius: 15px;
  opacity: 0;
  visibility: hidden;
  transition: opacity 0.4s, visibility 0.4s;
}

/* هدر و توضیحات کارت */
.ozcup-university-info h3 {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}

.ozcup-university-info p {
  font-size: 16px;
  color: #ddd;
  margin-bottom: 10px;
}

.ozcup-university-info .ozcup-university-tuition {
  font-size: 18px;
  font-weight: bold;
  color: #ffcc00;
  margin-bottom: 10px;
}

/* نمایش جزئیات کارت با هاور */
.ozcup-university-card:hover .ozcup-university-image {
  opacity: 0.2;
}

.ozcup-university-card:hover .ozcup-university-info {
  opacity: 1;
  visibility: visible;
}

/* دکمه جزئیات بیشتر */
.ozcup-details-button {
  display: inline-block;
  margin-top: 10px;
  padding: 12px 20px;
  background: #540654; /* تغییر رنگ به بنفش */
  color: #fff;
  text-decoration: none;
  border-radius: 8px;
  font-weight: bold;
  transition: background 0.3s;
  text-align: center;
  font-size: 16px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.2);
}

.ozcup-details-button:hover {
  background: #4b0082; /* تغییر رنگ به بنفش تیره‌تر */
}

/* 
   ============================
   حالت ریسپانسیو برای موبایل 
   ============================
*/
@media (max-width: 768px) {

  /* بک‌گراندی ملایم برای موبایل */
  body {
    background: linear-gradient(to right, #f9fcff, #eaf9ff);
  }

  /* تنظیم کانتینر اصلی برای فاصله بیشتر از حاشیه‌ها */
  .ozcup-universities-container {
    width: 100%;
    margin: 0 auto;
    padding-bottom: 30px;
  }

  /* کاهش اندازهٔ تیتر */
  .ozcup-main-header {
    font-size: 20px;
    padding: 12px 25px;
    margin-top: 30px;
  }

  /* تنظیم فاصلهٔ بالای انیمیشن */
  .ozcup-animation-container {
    margin-top: 80px; /* در موبایل نیز کمی پایین‌تر بیاید */
  }

  /* نمایش کارتها به‌صورت تک‌ستونه برای نظم بیشتر در موبایل */
  .ozcup-universities-grid {
    display: grid;
    grid-template-columns: 1fr;
    gap: 2px;
    margin-top: 25px;
    justify-items: center;
  }

  /* تنظیمات کارت‌ها در حالت موبایل */
  .ozcup-university-card {
    max-width: 400px;
    height: auto;
    margin: 0 auto;
    transform: none;
    opacity: 1;
    animation: none; /* حذف انیمیشن ورود */
  }

  .ozcup-university-card:hover {
    transform: none;
    box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
  }

  /* تصاویر کارت‌ها همیشه در حالت اولیه (بدون افکت hover) و با افکت بلور */
  .ozcup-university-card .ozcup-university-image {
    height: 200px;
    opacity: 0.7;
    transition: none;
    filter: none;
  }

  /* نمایش اطلاعات کارت به صورت overlay در پایین تصویر و همیشه قابل مشاهده */
  .ozcup-university-card .ozcup-university-info {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    padding: 10px;
    background: rgba(0, 0, 0, 0.4); /* پس‌زمینه نیمه شفاف */
    border-radius: 0;
    text-align: center;
    font-family: Arial, sans-serif;
    opacity: 1;
    visibility: visible;
    z-index: 2;
  }

  .ozcup-university-info h3 {
    font-size: 16px;
    margin-bottom: 4px;
    font-weight: normal;
  }

  .ozcup-university-info p {
    font-size: 12px;
    margin-bottom: 2px;
  }
}

/* FAQ.css - استایل‌های مدرن برای صفحه FAQ با پیشوند faq- */

/* اضافه کردن فونت Playfair Display برای سوالات و عنوان */
@import url('https://fonts.googleapis.com/css2?family=Playfair+Display:wght@400;700&display=swap');

/* تنظیمات کلی صفحه */
.faq-faq-container {
  width: 90%;
  max-width: 1200px;
  margin: 100px auto 50px;
  padding: 20px 30px 50px;
  font-family: "Poppins", sans-serif;
  background: #f9f9f9;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  box-sizing: border-box;
  direction: ltr;
  text-align: left;
}

/* راست‌چین کردن متون فارسی */
.faq-rtl {
  direction: rtl;
  text-align: right;
}

/* استایل دکمه‌های تغییر زبان (در صورت نمایش) */
.faq-language-selector {
  width: 90%;
  margin: 20px auto 0;
  display: flex;
  justify-content: flex-start;
  padding: 0 20px;
  box-sizing: border-box;
}



/* استایل عنوان صفحه سوالات متداول */
.faq-faq-header {
  font-size: 2.5rem;
  margin: 30px 0;
  color: #4B0082;
  text-align: center;
  font-family: "Playfair Display", serif;
  font-weight: 700;
}

/* استایل لیست سوالات متداول */
.faq-faq-list {
  margin-top: 20px;
  padding: 0 20px;
  box-sizing: border-box;
}

/* استایل آیتم‌های سوالات متداول */
.faq-faq-item {
  background: #fff;
  border-radius: 8px;
  margin-bottom: 15px;
  box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease;
  animation: fade-in 1.2s ease-in-out;
}

.faq-faq-item:hover {
  transform: translateY(-2px);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.15);
}

/* استایل بخش سوال */
.faq-faq-question {
  cursor: pointer;
  padding: 15px 20px;
  background-color: #F3E5F5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 1.2rem;
}

.faq-faq-question h3 {
  margin: 0;
  font-family: "Playfair Display", serif;
  font-size: 1.2rem;
  font-weight: 400;
  letter-spacing: 0.5px;
  color: #4B0082;
}

/* استایل بخش پاسخ */
.faq-faq-answer {
  padding: 15px 20px;
  border-top: 1px solid #ddd;
  font-size: 1rem;
  line-height: 1.6;
  color: #4B0082;
}

/* راست‌چین کردن متون در زبان فارسی در سوال و پاسخ */
.faq-rtl .faq-faq-question,
.faq-rtl .faq-faq-answer {
  text-align: right;
}

/* انیمیشن ساده برای ظاهر شدن آیتم‌ها */
@keyframes fade-in {
  from {
    opacity: 0;
    transform: translateY(-15px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

/* تنظیمات واکنش‌گرا برای گوشی‌ها */
@media (max-width: 768px) {
  .faq-faq-container {
    width: 95%;
    margin-top: 50px;
    padding: 15px 20px 30px;
  }
  
  .faq-faq-header {
    font-size: 2rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  
  .faq-faq-question {
    padding: 12px 15px;
    font-size: 1.1rem;
  }
  
  .faq-faq-answer {
    padding: 12px 15px;
    font-size: 0.95rem;
  }
  
  /* کاهش افکت‌های hover برای لمسی */
  .faq-faq-item:hover {
    transform: none;
    box-shadow: 0px 5px 12px rgba(0, 0, 0, 0.1);
  }
  
  .faq-language-selector {
    flex-direction: column;
    align-items: stretch;
    padding: 0 10px;
  }
  
  .faq-language-selector button {
    width: 100%;
    margin: 5px 0;
    font-size: 15px;
  }
}

@import url('https://fonts.googleapis.com/css2?family=Lobster&family=Playfair+Display:wght@400;700&display=swap');

/* تنظیمات کلی */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

/* تنظیمات مخصوص زبان فارسی */
html[lang="fa"],
body[lang="fa"],
[lang="fa"] {
  direction: rtl;
  text-align: right;
  unicode-bidi: isolate;
  font-family: "Tahoma", "IRANSans", sans-serif;
}

/* تنظیمات عمومی برای زبان‌های LTR (English, Turkish) */
html:not([lang="fa"]),
body:not([lang="fa"]) {
  direction: ltr;
  text-align: left;
}

/* بک‌گراند کلی صفحه */
.home-container {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  padding-bottom: 0;
}

/* رنگ بنفش برای تیترها */
.home-company-description,
.home-university-header,
.home-services-header,
.home-university-comparison-header {
  color: #4B0082;
}

/* استایل اسلایدر */
.home-slider-container {
  width: 80%;
  margin-top: 20px;
}

.slick-slider {
  width: 100%;
}

.slick-slide img {
  width: 100%;
  border-radius: 8px;
  height: auto;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
}
/* توضیحات شرکت */
.home-company-description {
  width: 85%;
  margin: 70px auto;
  padding: 25px;
  font-size: 18px;
  text-align: center;
  background: #f8f8f8;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  line-height: 1.7;
  unicode-bidi: isolate;
  direction: rtl;
}

/* کارت‌های دانشگاه */
.home-university-cards-container {
  width: 85%;
  margin-top: 30px;
  text-align: center;
}

.home-university-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.home-university-cards {
  display: flex;
  justify-content: space-around;
  gap: 20px;
  flex-wrap: wrap;
}

.home-university-card {
  position: relative;
  width: 250px;
  height: 350px;
  border-radius: 10px;
  overflow: hidden;
  background: #f8f8f8;
  transition: transform 0.3s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  border: 2px solid #4B0082; /* نوار بنفش رسمی */
}

.home-university-card:hover {
  transform: translateY(-8px);
}

.home-university-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-university-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(75, 0, 130, 0.92);
  padding: 15px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.home-university-card:hover .home-university-info {
  opacity: 1;
  background: linear-gradient(135deg, #4B0082, #7C4DFF);
  color: #ffffff;
  transition: background 0.3s, color 0.3s;
}

/* خدمات مهاجرتی */
.home-services-container {
  width: 85%;
  margin-top: 40px;
  text-align: center;
}

.home-services-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

.home-services-list {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}

.home-service-card {
  position: relative;
  width: 260px;
  height: 370px;
  border-radius: 10px;
  overflow: hidden;
  background: #c0c0c0;
  transition: transform 0.3s;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.4);
  border: 2px solid #4B0082; /* نوار بنفش رسمی */
}

.home-service-card:hover {
  transform: translateY(-8px);
}

.home-service-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.home-service-info {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(75, 0, 130, 0.92);
  padding: 15px;
  text-align: center;
  opacity: 0;
  transition: opacity 0.3s;
}

.home-service-card:hover .home-service-info {
  opacity: 1;
  background: linear-gradient(135deg, #4B0082, #7C4DFF);
  color: #ffffff;
  transition: background 0.3s, color 0.3s;
}
/* جدول مقایسه دانشگاه‌ها */
.home-university-comparison {
  width: 85%;
  margin: 30px auto;
  text-align: center;
}

.home-university-comparison-header {
  font-size: 24px;
  font-weight: 600;
  margin-bottom: 20px;
}

/* جدول در موبایل */
.home-comparison-table-container {
  width: 100%;
  overflow-x: auto;
}

.home-comparison-table {
  width: 100%;
  border-collapse: collapse;
  margin: 20px auto;
  font-size: 16px;
}

html[lang="fa"] .home-comparison-table {
  direction: rtl;
  text-align: center;
}
html:not([lang="fa"]) .home-comparison-table {
  direction: ltr;
  text-align: center;
}

.home-comparison-table th,
.home-comparison-table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: center;
}

.home-comparison-table th {
  background-color: #4B0082;
  color: #ffffff;
  font-weight: bold;
}

/* واکنش‌گرایی برای موبایل */
@media (max-width: 768px) {
  .home-slider-container {
    width: 95%;
    margin-top: 15px;
  }

  .home-company-description {
    width: 95%;
    padding: 20px;
    font-size: 16px;
  }

  .home-university-card,
  .home-service-card {
    width: 90%;
    max-width: 320px;
  }

  .home-university-cards,
  .home-services-list {
    flex-direction: column;
    align-items: center;
    gap: 10px;
  }

  .home-university-card .home-university-info,
  .home-service-card .home-service-info {
    opacity: 1;
    background: linear-gradient(135deg, #4B0082, #7C4DFF);
    color: #ffffff;
    transition: none;
  }

  .home-comparison-table {
    font-size: 14px;
  }

  .home-comparison-table th,
  .home-comparison-table td {
    padding: 10px 8px;
  }

  .home-university-comparison-header {
    font-size: 20px;
  }
}

.uni-footer {
  background: #ffffff; /* رنگ بنفش پاستیلی لایت */
  padding: 20px;
  font-family: 'Segoe UI', sans-serif;
  color: #4A148C;
  box-shadow: 0 -3px 10px rgba(0, 0, 0, 0.05);
  margin-top: 10px;
  transition: background 0.5s ease;
}

.uni-footer-container {
  display: flex;
  justify-content: space-evenly;
  align-items: flex-start;
  flex-wrap: nowrap;
  gap: 20px;
}

.uni-footer-card {
  flex: 1;
  max-width: 100%;
  min-width: 200px;
}

/* تنظیم تراز متن برای حالت‌های مختلف در زبان فارسی */
.rtl-footer .uni-footer-container {
  flex-direction: row-reverse;
}

/* استایل عناوین و متون */
.uni-footer-card h3 {
  font-size: 1.2rem;
  color: #4A148C;
  margin-bottom: 8px;
  padding-bottom: 4px;
  border-bottom: 1px solid #9C27B0;
  transition: border-color 0.3s ease;
}

.uni-footer-card p,
.uni-footer-card ul,
.uni-footer-card a {
  font-size: 0.9rem;
  color: #4A148C;
  line-height: 1.4;
  transition: color 0.3s ease;
}

.uni-footer-card ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.uni-footer-card ul li {
  margin-bottom: 8px;
}

.uni-footer-card a {
  text-decoration: none;
}

.uni-footer-card a:hover {
  color: #9C27B0;
}

/* آیکون‌های شبکه‌های اجتماعی */
.uni-social-icons {
  display: flex;
  justify-content: center;
  gap: 12px;
  font-size: 1.4rem;
}

.uni-social-icons a {
  color: #4A148C;
  transition: transform 0.3s ease, color 0.3s;
}

.uni-social-icons a:hover {
  color: #9C27B0;
  transform: scale(1.1);
}

/* بخش پایین فوتر */
.uni-footer-bottom {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  padding-top: 10px;
  text-align: center;
  font-size: 0.8rem;
  margin-top: 10px;
}
 
  /* تغییر ترتیب کارت‌ها با استفاده از order */
  @media (max-width: 600px) {
    .uni-footer-container {
      flex-direction: column;
      align-items: center;
    }
  
    /* ترتیب صحیح کارت‌ها برای موبایل */
    .footer-about {
      order: 1;
    }
    .footer-follow {
      order: 2;
    }
    .footer-links {
      order: 3;
    }
  
    .uni-footer-card {
      width: 100%;
      max-width: none;
      text-align: center;
      margin-bottom: 10px;
    }
  }